import React from "react";
import CFO from "../components/Services/CFO";

const Service7 = () => {
  return (
    <>
      <CFO />
    </>
  );
};

export default Service7;
