import React from "react";
import IPR from "../components/Services/IPR";

const Service4 = () => {
  return (
    <>
      <IPR />
    </>
  );
};

export default Service4;
