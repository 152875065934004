import React from "react";
import MA from "../components/Services/MA";

const Service9 = () => {
  return (
    <>
      <MA />
    </>
  );
};

export default Service9;
