import React from "react";
import IA from "../components/Services/IA";

const Service8 = () => {
  return (
    <>
      <IA />
    </>
  );
};

export default Service8;
