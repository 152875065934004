import React from "react";
import IES from "../components/Services/IES";

const Service2 = () => {
  return (
    <>
      <IES />
    </>
  );
};

export default Service2;
