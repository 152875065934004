import React from "react";
import SCL from "../components/Services/SCL";

const Service5 = () => {
  return (
    <>
      <SCL />
    </>
  );
};

export default Service5;
