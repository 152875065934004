import React from "react";

import TeamPage from "../components/Team";
const Team = () => {
  return (
    <>
      <TeamPage/>
    </>
  );
};

export default Team;
