import React from "react";
import FDI from "../components/Services/FDI";

const Service6 = () => {
  return (
    <>
      <FDI />
    </>
  );
};

export default Service6;
