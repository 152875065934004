import React from "react";
import DIT from "../components/Services/DIT";

const Service3 = () => {
  return (
    <>
      <DIT />
    </>
  );
};

export default Service3;
