import React from "react";
import NGO from '../components/Services/NGO'

const Service1 = () => {
  return (
    <>
      <NGO/>
    </>
  );
};

export default Service1;
